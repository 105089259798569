import axios from 'axios'
class Consultatabla {
  
    async listar(tabla){
        try{
        let resp=''
        let json={"tabla":tabla};
        resp= await axios.post(window.url+"/consultatabla",json, {headers: {'Key': window.key}})
        return resp.data
        }
        catch(e)
        {
            return e
        }
    }
}

export default Consultatabla