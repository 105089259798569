<template>
  <div class=" row">
    <div class="col-md-6 m-2em justify-content-center">
      <div class="row mb-1">
        <div class="col-8 text-left">
          <input type="text" class="form-control" v-model="searchValue" placeholder="Buscar">
        </div> 
        <div class="col-4 text-right">
          <div class="icon"> 
            <i class="bi bi-reply" @click="volver()" style="font-size: 1.7em; color: green;"></i>
          </div>
        </div>     
      </div>     
      <EasyDataTable
        theme-color="#1d90ff"
        table-class-name="customize-table"  important
        :headers="headers"
        :items="filas"
        :rows-per-page="5"
        header-text-direction="center"
        body-text-direction="center"
        :search-field="searchField"
        :search-value="searchValue"
        :v-model=itemsSelected
        @click-row="showRow"
      />
    </div>
    <div class="col-md-6 mt-5 justify-content-center">
      <form @submit.prevent="codigo" class="needs-validation">        
        <div class="row mb-3">
          <div class="col-12 text-left"><input type="text" v-model="nombre" name="nombre" class="linea" placeholder="Nombre" required></div>
        </div>
        <div class="row mb-3">
          <div class="col-12 text-left"><input type="text" v-model="usuario" name="usuario" class="linea" placeholder="Usuario" required></div>
        </div>
        <div class="row mb-3">
          <div class="col-12 text-left"><input type="password" v-model="password" name="password" class="linea" placeholder="Password" required></div>
        </div>
        <div class="row mb-3">
          <div class="col-12 text-left"><input type="password" v-model="password1" name="password1" class="linea" placeholder="Confirmar password" required></div>
        </div>
        <select class="linea" v-model="rol" name="rol" required>
          <option v-for="item in valores" :key="item" :value="item.rol">{{ item.rol }}</option>
        </select>
        <hr>
        <div class="row mt-3">
          <div class="col-12 text-right">
            <button type="submit" class="btn" style="background-color: green;color:white">GUARDAR</button>
          </div>
        </div>
      </form>
  </div>
  </div>
  <Teleport to="body">
    <modal :show="popup" @close="showModal = false">
      <template #body>
        <hr width="70%" size="5" color="green" border-color="green">
        <h6>{{mensaje}}</h6><br>
        <div class="row">
          <div class="col-12 d-flex justify-content-center"><a @click="popup=0" class="btn btn-success">Aceptar </a></div>
        </div>
      </template>
    </modal>
  </Teleport> 
</template>

<script setup >
  import { ref,onBeforeMount} from 'vue';
  import roles from '../servicios/Consultatabla.js';
  import usrs from '../servicios/Usuarios.js';
  import EasyDataTable from 'vue3-easy-data-table';
  import 'vue3-easy-data-table/dist/style.css';
  import Modal from '@/components/Modal.vue'
  const apir=new roles();
  const usr=new usrs();
  const searchValue = ref("");
  var popup = ref(false)
  var usuario=ref('')
  var mensaje=ref('')
  var flag = ref(1)
  var id=ref(null)
  var valores=ref([])
  var itemsSelected=ref([])
  var nombre = ref()
  var usuario = ref()
  var password = ref()
  var password1 = ref()
  var rol = ref()
  var estado=ref('Activo')
 
  function showRow(dato)
  {
    console.log(dato)
    nombre.value=dato.nombre
    usuario.value=dato.usuario
    rol.value=dato.tipo
    flag=0
  };

  const headers=ref([
      { text: "Nombre", value: "nombre" },
      { text: "Usuario", value: "usuario", sortable: true },
    ]);

    const filas=ref([
      { "name": "Curry", "height": 178, "weight": 77, "age": 20 },
      { "name": "James", "height": 180, "weight": 75, "age": 21 },
      { "name": "Jordan", "height": 181, "weight": 73, "age": 22 }
    ]);

  onBeforeMount (() => {
    consultar()
  })

  async function consultar(){
    const users=await apir.listar("usuarios")
    filas.value=users
    const roles=await apir.listar("roles")
    valores.value=roles
  }

  async function codigo(){
    if (password.value==password1.value)
    {
      const suser=await usr.salvar(id.value,nombre.value,usuario.value,password.value,rol.value,estado.value,"")
      if(typeof suser==='object')
      {
        filas.value=suser
        mensaje.value='Procedimiento exitoso'
        popup.value=true
        nombre.value='',usuario.value='',password.value='',password1.value='',rol.value=''
      }
      else
      {
        mensaje.value=suser
        popup.value=true
      }
    }
    else
    {
        mensaje.value='Password no coincide'
        popup.value=true
    }
    }
    function volver()
    {
      router.push("menu")
    }
</script>

<style>
.customize-table {
  --easy-table-border: 1px none;
  --easy-table-row-border: 1px solid #445269;
  --easy-table-header-font-size: 1em;
  --easy-table-header-height: 10px;
  --easy-table-header-font-color: black;
  --easy-table-header-background-color: transparent;
  --easy-table-header-item-padding: 1px 1px;
  --easy-table-body-even-row-font-color: blue;
  --easy-table-body-even-row-background-color: blue;
  --easy-table-body-row-font-color: black;
  --easy-table-body-row-background-color: transparent;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 100%;
  --easy-table-body-row-hover-font-color: white;
  --easy-table-body-row-hover-background-color: gray;
  --easy-table-body-item-padding: 1px 1px;
  --easy-table-footer-background-color: green;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 100%;
  --easy-table-footer-padding: 0px 1px;
  --easy-table-footer-height: 50px;
  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;
  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;
  --easy-table-loading-mask-background-color: #2d3a4f;
}
.linea {
  outline: none;
  background-color: transparent;
  border: 0;
  border-bottom:1px solid gray;
  display: block;
  width: 100%;
}
</style>
