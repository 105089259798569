<template>
  <div>
  <nav class="navbar mb-3">
    <img src="../assets/logon.png" class="ml-1 mb-1 mt-1" transition="scale-transition" width="50"/>
    <i class="fas fa-fw fa-list" style="font-size: 1.3rem; color: white;" data-toggle="modal" data-target="#menu"></i>
      <a class="nav-link" data-bs-toggle="dropdown" style="color:white;margin-right:5px;font-size:1rem" >{{this.usuario}}</a>
  </nav>
  </div>
  <div class="modal fade" id="menu" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content mr-3">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel">Menu principal</h6>
            <button ref="cerrar" class="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"></span>
                </button>
                </div>
                <div class="row m-2">
                    <button v-show="this.registrar" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/registrar')"><i style="color:white;"></i>Registrar Proyecto</button>
                    <button v-show="this.ingenieria" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/ingenieria')"><i style="color:white;"></i>Planeacion</button>
                    <button v-show="this.proyectos" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/dashboard')"><i style="color:white;"></i>Proyectos</button>
                    <button v-show="this.bodega" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/bodega')"><i style="color:white;"></i>Bodega</button>
                    <button v-show="this.traslado" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/trasladobodega')"><i style="color:white;"></i>Traslado Celular</button>
                    <button v-show="this.traslado" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/trasladobodegan')"><i style="color:white;"></i>Traslado Computador</button>
                    <button v-show="this.campo" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/recibocampo')"><i style="color:white;"></i>Recibo mercancia</button>
                    <button v-show="this.imprevistos" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/imprevistos')"><i style="color:white;"></i>Servicios</button>
                    <button v-show="this.imprevistos" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/api')"><i style="color:white;"></i>API</button>
                    <button class="btn btn-outline-danger mb-1" type="button"  @click="funcion('/emergencia')"><i style="color:white;"></i> EMERGENCIAS</button>
                    <button class="btn btn-outline-success mb-1" type="button"  @click="salir()"><i style="color:white;"></i> Salir</button>
                  </div>
              </div>
        </div>
    </div>  
</template>

<script>
export default {
  name: 'EncabezadoVue',
  components: {},
  data: function () {
    return {
      usuario:null,
      registrar:0,
      ingenieria:0,
      proyectos:0,
      bodega:0,
      traslado:0,
      perfil:0,
      campo:0,
      imprevistos:0
    };
  },
  methods: {
    funcion(opcion){
      this.$refs.cerrar.click();
      this.$router.push(opcion)
      //this.$emit("refrescar",opcion)
    },
    salir()
    {
      this.$refs.cerrar.click();
      let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
      let json={"api_token":token,};
      axios.post(window.url+"/logout",json)
        .then(data=>{
        localStorage.removeItem('d1');
        localStorage.removeItem('d2');
        this.$router.push('/');
        })
        .catch(error => {
        console.log(error)
        }
      )
    }
}
}
</script>
