<template>
<div class="container mt-5 mb-3">
    <div class="row m-3">
        <div class="col-md-4" v-for="(opcion,index) in opciones" :key="opcion">
            <div class="card p-3 mb-3 shadow p-3" @click="funcion(index)">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <div class="icon"> <i :class="opcion.icono" style="font-size: 2rem; color: green;"></i></div>
                    </div>
                </div><hr width="70%" size="5" color="green" border-color="green">
                <div class="mt-3">
                    <h3 class="heading" style="text-align:center;">{{opcion.menu}}</h3>
                    <div class="mt-5">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
 import { ref,onBeforeMount} from 'vue';
 import router from "../router";
 import menu from '../servicios/Consultatabla.js';
 import { useMemoriaStore } from '../store/memoria.js'
 const api=new menu();
 var opciones=ref([])
 const store=useMemoriaStore()
    function funcion(id){
        switch (id) {
            case 0:
            router.push("config");
            break;
            case 1:
            router.push("dashboard");
            break;
            case 2:
            router.push("out");
            break;
        }
    }
    onBeforeMount (() => {
    consultar()
  })

  async function consultar(){
    opciones.value=store.menu
}

 </script>
