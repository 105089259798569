import { defineStore } from 'pinia'
export const useMemoriaStore=defineStore('memoria',{
    state:()=>{
        return{
            count:1,
            token:null,
            user:null,
            perfil:null,
            codigo:'987654',
            menu:null
        }
    },
    getters:{
    },
    actions:{
        registro(tk,us,pr,mn){
            this.token=tk
            this.user=us
            this.perfil=pr
            this.menu=mn
        },
        newqr(cd){
            this.codigo=cd
        }

    },
    persist:true

})