import axios from 'axios'

class Autenticacion {
  
    async login(usuario,password){
        try{
        let resp=''
        let json={"usuario":usuario,"password":password};
        resp= await axios.post(window.url+"/login",json, {headers: {'Key': window.key}});
        return resp.data
        }
        catch(e)
        {
            return e
        }
    }
}

export default Autenticacion