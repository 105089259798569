import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import Avatar from "vue3-avatar";
import 'bootstrap-icons/font/bootstrap-icons.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
app.config.globalProperties.window =window
window.flogin='2'
app.use(pinia)
app.use(router)
app.use(store)
app.use(Avatar);
app.mount('#app');
app.config.globalProperties.window = window;
//window.url = "http://localhost:4000";
window.url = "https://cobolintegra.com/api";
window.key = "cH*9=6KHuXet+1tf&).NF%]#58XUJN";
