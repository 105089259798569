<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="mt-3">
            <img src="../assets/logo.png" width="60"><hr width="70%" size="3" color="green" border-color="green" noshade>
          </div>
          <div class="card-body p-5 p-sm-5">
            <div>
              <div class="form-floating mt-1 mb-3">
                <input type="text" v-model="usuario" class="form-control">
                <label for="floatingInput">Usuario</label>
              </div>
              <div class="form-floating mb-4">
                <input type="password" v-model="password" class="form-control" autocomplete="on">
                <label for="floatingPassword">Contraseña</label>
              </div>
              <div v-show=msj class="text-center" style="color:red">
                {{mensaje}}
              </div>               
              <br>
              <div class="d-grid pb-3 pt-3">
                <button class="btn btn-success btn-login" @click="login()">Ingresar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   
   <script setup>
   import { ref } from 'vue'
   import Autenticacion from '../servicios/Autenticacion.js';
   import { useMemoriaStore } from '../store/memoria.js'
   import router from "../router";
   import ctabla from '../servicios/Consultatabla.js';
   const apic=new ctabla();
   const store=useMemoriaStore()
        let mensaje =ref('error')
        let msj =ref(0)
        let usuario =ref('')
        let password =ref('')
        const login=async()=>{
        const api=new Autenticacion();
        const resp=await api.login(usuario.value,password.value)
        if(resp.status==200)
        {
          store.registro(resp.token,resp.nombre,resp.tipo)
          const mnu=await apic.listar("menu")
          store.registro(resp.token,resp.nombre,resp.tipo,mnu)
          router.push("menu");
        }
        else
        { 
            mensaje.value='Datos no validos'
            msj.value=1
            usuario.value=''
            password.value=''
            setTimeout(function() {msj.value=0;}, 1000)           
        }
        }
   </script>
   