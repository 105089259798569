<template>
    <div class="row">
      <div class="col-md-6 d-flex justify-content-center mb-5">
        <qrcode-vue
            :value="Tituloe"
            level="L"
            render-as="svg"
            background='transparent'
            foreground='#000000'
            :gradient='false'
            gradient-type="linear"
            gradient-start-color="#000000"
            gradient-end-color="#38bdf8"
            :size=150
        />
      </div>
      <div class="col-md-6 justify-content-center">
        <div class="row d-flex justify-content-center">
          <div class="form-check form-switch pl-5 pb-4" >
            <input class="form-check-input" type="checkbox" v-model="Autonomo"  role="switch" id="flexSwitchCheckDefault">
            <label class="form-check-label" for="flexSwitchCheckDefault">Autonomo</label>
          </div>
        </div>
        <form @submit.prevent="codigo" class="needs-validation">        
          <div class="row mb-3">
            <div class="col-12 text-left "><input type="text" v-model="nombre" class="linea" placeholder="Nombre" required></div>
          </div>
          <div class="row mb-3">
            <div class="col-12 text-left "><input type="text" v-model="ssid" class="linea" placeholder="SSID" required></div>
          </div>
          <div class="row mb-3">
            <div class="col-12 text-left "><input type="text" v-model="password" class="linea" placeholder="Password" required></div>
          </div>
          <div class="row mb-3">
            <div class="col-12 text-left "><input type="text" v-model="api" class="linea" placeholder="URL" required></div>
          </div>
          <div class="row mb-3">
            <div class="col-12 text-left "><input type="text" v-model="columnas" class="linea" placeholder="Columnas" required></div>
          </div><hr>
          <div class="row mb-3">
            <div class="col-6 text-left">
              <button type="submit" class="btn" style="background-color: green;color:white">CREAR QR</button>
            </div>
            <div class="col-6 text-right">
              <button class="btn" style="background-color: green;color:white" @click="volver()">REGRESAR</button>
            </div>
          </div>
        </form>
      </div>  
    </div>  
  </template>
    <script setup>
    require('@/css/sb-admin-2.min.css')
    import {ref} from 'vue';
    import router from "../router";
    import QrcodeVue from 'qrcode.vue'
    var Tituloe = ref('')
    var ssid = ref('')
    var password = ref('')
    var cadena = ref([])
    var texto = ref('')
    var confi = ref('')
    var encri=ref('')
    var api = ref('')
    var mostrar=ref('true')
    
  
  
  
    function codigo()
    {
      encri.value=''
      texto.value="1Xkv(qDQ<6UW9b3,id*/_m?{h2[CGjso@B)gEPt`cy:OJ=^nR-I'&wl]H\\uza. %MK0;|LT~e}Yp\"$A8SNr7+x#Z4>f!VF5"
      confi.value='CNFGCBL|'+ssid.value+'|'+password.value+'|'+api.value
      for (var i =0; i < confi.value.length; i++) {
        encri.value=encri.value+(((texto.value.indexOf(confi.value.substring(i,i+1))).toString(16)).toUpperCase()).padStart(2, '0')
      }
      Tituloe.value=encri.value
      mostrar.value=1
    }
  
    function obtener(){
      for (var i = 32; i < 127; i++) {
        cadena.value.push(String.fromCharCode(i))
      }
      shuffle(cadena.value);
      for (var i = 0; i < cadena.value.length; i++) {
        texto.value=texto.value+cadena.value[i]
      }
      return texto.value
    }
  
    function shuffle(array) { 
    for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]];
    } 
    }
  
    function volver()
    {
      router.push("menu")
    }
  
  </script> 

  <style>
  .linea {
  outline: none;
  background-color: transparent;
  border: 0;
  border-bottom:1px solid gray;
  display: block;
  width: 100%;
}
  </style>
      