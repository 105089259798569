<template>
  <div class="row">
    <Encabezado />
  </div>
  <div class="row" style="margin-left:1em;margin-right: 1em;margin-bottom:1em;">
    <div>
      <Tabs>
        <Tab value="RED">
          <hr>
          <Red />
        </Tab>
        <Tab value="USUARIOS">
          <hr>
          <Tabla />
          </Tab>
        <Tab value="EQUIPOS">
          <hr>
          <p>This is the content of Tab 3</p>
        </Tab>
      </Tabs>
    </div>
  </div>

</template>
  <script setup>
  require('@/css/sb-admin-2.min.css')
  import { Tabs, Tab } from 'super-vue3-tabs'
  import Encabezado from '@/components/Encabezado.vue';
  import Red from '@/components/Red.vue';
  import Tabla from '@/components/TablaUsuarios.vue';
</script> 
    