import axios from 'axios'

class Autenticacion {
  
    async salvar(id,nombre,usuario,password,tipo,estado,token){
        try{
        let resp=''
        let json={"usuario":id,"nombre":nombre,"usuario":usuario,"password":password,"tipo":tipo,"estado":estado,"token":token};
        resp= await axios.post(window.url+"/addusuario",json, {headers: {'Key': window.key}});
        return resp.data
        }
        catch(e)
        {
            return e
        }
    }
}

export default Autenticacion