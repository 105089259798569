import { createRouter, createWebHashHistory } from 'vue-router'
import ConfigView from '../views/ConfigView.vue'
import MenuView from '../views/MenuView.vue'
import OutView from '../views/OutView.vue'
import Login from '../views/Login.vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/config',
    name: 'config',
    component: ConfigView
  },
  {
    path: '/out',
    name: 'out',
    component: OutView
  },       
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
